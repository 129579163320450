import '@mrhenry/wp--bugsnag-config';

import { initMenuButtons, MrTabs } from '@mrhenry/wp--mr-interactive';

/* Modules */
import './modules/mr-accordion';
import './modules/mr-locations-by-postal-code';
import './modules/input-sink';
import './modules/navigation-overlay';
import './modules/mr-blocked-content';

customElements.define( 'mr-tabs', MrTabs );
initMenuButtons();
