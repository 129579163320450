class MrBlockedContent extends HTMLElement {

	#clickHandler = ( e: MouseEvent ): void => {
		// Ignore clicks with modifier keys: shift, ctrl, alt,...
		if ( e.metaKey ) {
			return;
		}

		// Check if target exist and is instance of HTMLElement.
		if ( !e.target || !( e.target instanceof HTMLElement ) ) {
			return;
		}

		if ( !e.target.hasAttribute( 'data-unblocks-content' ) ) {
			return;
		}

		e.preventDefault();

		this.unblockGroup();
		this.show();
	};

	show() {
		window.requestAnimationFrame( () => {
			const contentStr = this.getAttribute( 'data-content' );
			this.removeAttribute( 'data-content' );

			if ( !contentStr ) {
				return;
			}

			const content = JSON.parse( contentStr );

			if ( !content || !content.html ) {
				return;
			}

			this.innerHTML = content.html;
			this.setAttribute( 'data-state', 'unblocked' );
		} );
	}


	unblockGroup() {
		const group = this.getAttribute( 'data-group' );
		if ( !group ) {
			return;
		}

		try {
			const alreadyUnblockedStr = window.sessionStorage.getItem( 'unblocked-content' );
			const alreadyUnblocked: Array<string> = [];
			if ( alreadyUnblockedStr ) {
				const parsed = JSON.parse( alreadyUnblockedStr );
				if ( Array.isArray( parsed ) ) {
					parsed.forEach( ( x ) => {
						if ( 'string' === typeof x ) {
							alreadyUnblocked.push( x );
						}
					} );
				}
			}

			if ( alreadyUnblocked.includes( group ) ) {
				// Do nothing, already unblocked
				return;
			}

			window.sessionStorage.setItem( 'unblocked-content', JSON.stringify( [
				...alreadyUnblocked,
				group,
			] ) );
		} catch ( error ) {
			console.warn( error );
		}
	}

	checkGroupIsUnblocked() {
		const group = this.getAttribute( 'data-group' );
		if ( !group ) {
			return false;
		}

		try {
			const alreadyUnblockedStr = window.sessionStorage.getItem( 'unblocked-content' );
			const alreadyUnblocked: Array<string> = [];
			if ( alreadyUnblockedStr ) {
				const parsed = JSON.parse( alreadyUnblockedStr );
				if ( Array.isArray( parsed ) ) {
					parsed.forEach( ( x ) => {
						if ( 'string' === typeof x ) {
							alreadyUnblocked.push( x );
						}
					} );
				}
			}

			if ( alreadyUnblocked.includes( group ) ) {
				return true;
			}
		} catch ( error ) {
			console.warn( error );

			return false;
		}

		return false;
	}

	// Life cycle
	connectedCallback() {
		window.requestAnimationFrame( () => {
			if ( this.checkGroupIsUnblocked() ) {
				this.show();

				return;
			}

			this.addEventListener( 'click', this.#clickHandler );
		} );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );
	}
}

customElements.define( 'mr-blocked-content', MrBlockedContent );
