import { Location } from './location';

// https://stackoverflow.com/a/51819649/4263818
function distance( lng_1: number, lat_1: number, lng_2: number, lat_2: number ): number {
	const rad_lat_1 = Math.PI * lat_1 / 180;
	const rad_lat_2 = Math.PI * lat_2 / 180;
	const theta = lng_1 - lng_2;
	const rad_theta = Math.PI * theta / 180;
	let dist = Math.sin( rad_lat_1 ) * Math.sin( rad_lat_2 ) + Math.cos( rad_lat_1 ) * Math.cos( rad_lat_2 ) * Math.cos( rad_theta );

	if ( 1 < dist ) {
		dist = 1;
	}

	dist = Math.acos( dist );
	dist = dist * 180 / Math.PI;
	dist = dist * 60 * 1.1515;

	return dist;
}

export function distanceSorter( lng: number, lat: number ) {
	return ( a: Location, b: Location ) => {
		if ( typeof a.distance === 'undefined' ) {
			a.distance = distance( lng, lat, a.lng, a.lat );
		}

		if ( typeof b.distance === 'undefined' ) {
			b.distance = distance( lng, lat, b.lng, b.lat );
		}

		return a.distance - b.distance;
	};
}
