import type { Location } from './location';

export async function fetchAllLocations() {
	const resp = await fetch( '/wp-json/locations/all.json' );
	if ( !resp.ok ) {
		return;
	}

	const data = await resp.json() as Array<Location>;

	return data;
}
