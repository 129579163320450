async function fetchMapboxLocation( query: string ) {
	if ( !query ) {
		return;
	}

	const resp = await fetch( `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=be&types=region,postcode,district,place,locality,neighborhood,address&language=nl&autocomplete=false&access_token=pk.eyJ1IjoibXJoZW5yeSIsImEiOiJjbGtpMzhzNXMwMjJvM2VuOW13Y2x0ZDNhIn0.ThHx5ibaINT3druES_LQeg` );
	if ( !resp.ok ) {
		return;
	}

	const data = await resp.json();

	return data;
}


export async function fetchMapboxLocationLngLat( query: string ) {
	const data = await fetchMapboxLocation( query );

	if ( !data || !data.features ) {
		return;
	}

	const result = data.features;
	if ( !result.length ) {
		return;
	}

	const firstResult = result[0];
	if ( !firstResult.center ) {
		return;
	}

	if ( !firstResult.center.length || 2 !== firstResult.center.length ) {
		return;
	}

	return {
		lng: parseFloat( firstResult.center[0] ),
		lat: parseFloat( firstResult.center[1] ),
	};
}
